import React, {useEffect, useRef, useState} from 'react';
import './Tooltip.scss';

const Tooltip = ({children}) => {
    const [overflow, setOverflow] = useState({
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    });
    const innerRef = useRef(null);

    useEffect(() => {
        checkOverflow();
    }, []);

    const checkOverflow = () => {
        const rect = innerRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        setOverflow({
            top: rect.top < 0 ? Math.abs(rect.top) : 0,
            left: rect.left < 0 ? Math.abs(rect.left) : 0,
            bottom: rect.bottom > viewportHeight ? rect.bottom - viewportHeight : 0,
            right: rect.right > viewportWidth ? rect.right - viewportWidth : 0
        });
    }

    return (
        <span className="tooltip">
            <span className="tooltip__button">{children[0]}</span>

            <span
                ref={innerRef}
                className="tooltip__inner"
                style={{
                    right: overflow.left > 0 ?  overflow.right - overflow.left - 10 : overflow.right
                }}
            >{children[1]}</span>
        </span>
    );
};

export default Tooltip;