import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
    }
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
    }
});

const authInterceptor = config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('tarot_token')}`;

    return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };