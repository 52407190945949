import {$authHost, $host} from "./index";

export const registration = async (user) => {
    const {data} = await $host.post('users', user);
    localStorage.setItem('tarot_token', data.token);
    return data;
};

export const login = async (user_ident) => {
    const {data} = await $host.post('login', {user_ident});
    localStorage.setItem('tarot_token', data.token);
    return data;
};

export const check = async () => {
    const {data} = await $authHost.get('me');
    // localStorage.setItem('tarot_token', data.token);
    // return jwtDecode(data.token);
    return data;
};

export const setLang = async (lang) => {
    const {data} = await $authHost.post('set_lang', {lang});
    return data;
};

export const getInvoiceLink = async () => {
    const {data} = await $authHost.get('get_invoice_link');
    return data;
}

export const getUserData = async () => {
    const {data} = await $authHost.get('get_info');
    return data;
}