import React, {useCallback} from 'react';
import { useTelegram } from './hooks/useTelegram';
import { useEffect, useState } from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import HistoryPage from './pages/HistoryPage/HistoryPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import { login, registration } from './api/user';
import Loader from './components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {setAppSize, setUser} from './store/mainSlice';
import './App.scss';
import HistoryCardPage from "./pages/HistoryCardPage/HistoryCardPage";
import Footer from './components/Footer/Footer';
import {useTranslation} from "react-i18next";

function App() {
    const dispatch = useDispatch();
    const { t, i18n  } = useTranslation();
    const { tg, tgUser } = useTelegram();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isAuthError, setAuthError] = useState(false);

    const user = useSelector((state) => state.main.user);
    const appWidth = useSelector((state) => state.main.appWidth);
    const appHeight = useSelector((state) => state.main.appHeight);
    const isFooterVisible = useSelector((state) => state.main.isFooterVisible);

    const saveUserData = useCallback((user) =>  dispatch(setUser(user)), [dispatch]);

    const goBack = useCallback(() => navigate(-1), [navigate]);

    const userRegistration = useCallback(() => {
        const params = {
            username: tgUser.username ? tgUser.username : '',
            user_ident: tgUser.id,
            first_name: tgUser.first_name,
            last_name: tgUser.last_name,
            language_code: tgUser.language_code,
        }
        const refParam = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
        let refCode, journalId;

        if (refParam) {
            [refCode, journalId] = refParam.split('_');

            params.referral_code = refCode;
        }

        registration(params)
            .then((response) => {
                saveUserData(response.user);

                if (refParam) {
                    navigate('/history/' + journalId, { state: { isReferralPrediction: true } });
                }

                setLoggedIn(true);
            })
            .catch((e) => {
                console.log(e);
                setAuthError(true);
            });
    }, [saveUserData, user.id, user.first_name, user.language_code, user.last_name, user.username]);

    const userLogin = useCallback(() => {
        const refParam = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
        let refCode, journalId;

        if (refParam) {
            [refCode, journalId] = refParam.split('_');
        }

        login(tgUser?.id)
            .then((response) => {
                saveUserData(response.user);

                if (refParam) {
                    navigate('/history/' + journalId, { state: { isReferralPrediction: true } });
                }

                setLoggedIn(true);
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    userRegistration();
                } else {
                    setAuthError(true);
                }
            });
    }, [saveUserData, user.id, userRegistration]);

    useEffect(() => {
        const html = document.querySelector('html');

        if (html) {
            html.setAttribute('lang', i18n.language)
        }
    }, [i18n.language]);

    useEffect(() => {
        location.pathname === '/' ? tg.BackButton.hide() : tg.BackButton.show();
    }, [location, tg.BackButton]);

    useEffect(() => {
        const fillWindowSize = () => {
            let windowHeight, windowWidth;

            if (window.visualViewport) {
                windowHeight = window.visualViewport.height;
                windowWidth = window.visualViewport.width;
            } else {
                windowHeight = window.innerHeight;
                windowWidth = window.innerWidth;
            }

            if (document) {
                dispatch(setAppSize({width: windowWidth, height: windowHeight}));
                document.documentElement.style.setProperty('--app-height', `${windowHeight}px`);
            }
        }

        fillWindowSize();

        window.visualViewport.addEventListener('resize', fillWindowSize);

        return () => {
            window.visualViewport.removeEventListener('resize', fillWindowSize);
        };
    }, [appHeight, appWidth]);

    useEffect(() => {
        userLogin();
    }, []);

    useEffect(() => {
        tg.ready();

        tg.setBackgroundColor('#1F1F1F');
        tg.setHeaderColor('#1F1F1F');
        tg.expand();
    }, [tg]);

    useEffect(() => {
        tg.onEvent('backButtonClicked', goBack);

        return () => tg.offEvent('backButtonClicked', goBack);
    }, [tg, goBack]);

    return (
        <div
            className={
                'App' +
                (!isFooterVisible ? ' footerHidden' : '')
            }
        >
            {!isLoggedIn ? (
                isAuthError ? (
                    <div className="page">
                        <p className="text-center">{ t('textErrorAuth') }</p>
                    </div>
                ) : (
                    <Loader />
                )
            ) : (
                <>
                    <Routes>
                        <Route index element={<HomePage />} />

                        <Route path="/history" element={<HistoryPage />} />

                        <Route path="/history/:id" element={<HistoryCardPage />} />

                        <Route path="/settings" element={<SettingsPage />} />

                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>

                    <Footer />
                </>
            )}
        </div>
    );
}

export default App;
