import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.scss';
import {useTranslation} from "react-i18next";

function Footer() {
    const { t } = useTranslation();
    const menu = [
        {text: t('menuPrediction'), url: '/'},
        {text: t('menuJournal'), url: '/history'},
        {text: t('menuSettings'), url: '/settings'}
    ];

    return (
        <div className="footer">
            <nav>
                {menu.map((elem, index) => (
                    <NavLink
                        key={index}
                        to={elem.url}
                        className={({isActive}) => isActive ? "footer__link active" : "footer__link"}
                    >{elem.text}</NavLink>
                ))}
            </nav>
        </div>
    );
}

export default Footer;
