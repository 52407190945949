import React, {useEffect, useState} from 'react';
import HistoryList from "../../components/HistoryList/HistoryList";
import {getHistory} from "../../api/cards";
import Loader from "../../components/Loader/Loader";
import './HistoryPage.scss';
import {useTranslation} from "react-i18next";

const HistoryPage = () => {
    const { t } = useTranslation();
    const [history, setHistory] = useState([]);
    const [pagination, setPagination] = useState({});
    const [isHistoryLoaded, setHistoryIsLoaded] = useState(false);
    const [isMoreHistoryLoaded, setMoreHistoryIsLoaded] = useState(true);

    const getHistoryList = (page = 1) => {
        if (page !== 1) {
            setMoreHistoryIsLoaded(false);
        }

        getHistory({ page })
            .then(response => {
                setPagination(response.meta?.pagination)

                setHistory((prevHistory) => [
                    ...prevHistory,
                    ...response.journals
                ]);
            })
            .catch(e => console.log(e))
            .finally(() => {
                setHistoryIsLoaded(true);
                setMoreHistoryIsLoaded(true);
            });
    }

    useEffect(() => {
        gtag("event", "opened_journal");

        setHistory([]);

        getHistoryList();
    }, []);

    return (
        <div className="historyPage page">
            {!isHistoryLoaded ?
                <Loader />
                :
                <>
                    {history?.length ?
                        <HistoryList history={history} />
                        :
                        <p className="historyPage__empty">{ t('textHistoryEmpty') }</p>
                    }

                    {pagination && pagination.current < pagination.pages ?
                        isMoreHistoryLoaded ?
                            <button
                                className="button"
                                onClick={() => getHistoryList(pagination.next)}
                            >{ t('loadMore') }</button>
                            :
                            <Loader className="moreLoader" />
                        : null
                    }
                </>
            }
        </div>
    );
};

export default HistoryPage;