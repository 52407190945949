export function useTelegram() {
    const tg = window.Telegram.WebApp;

    const tgCloseApp = () => {
        tg.close();
    }

    const tgMainButtonShow = () => tg.MainButton.show();
    const tgMainButtonHide = () => tg.MainButton.hide();

    const tgMainButtonToggle = () => {
        tg.MainButton.isVisible ? tgMainButtonHide() : tgMainButtonShow();
    }

    const tgMainButtonSetParams = (params) => tg.MainButton.setParams(params);

    return {
        tg,
        tgUser: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,

        tgCloseApp,
        tgMainButtonSetParams,
        tgMainButtonShow,
        tgMainButtonHide,
        tgMainButtonToggle
    }
}