import React from 'react';
import {NavLink} from "react-router-dom";

const NotFoundPage = () => {
    return (
        <div className="notFoundPage page">
            404 Not Found

            <p>
                <NavLink to="/">Back to main screen</NavLink>
            </p>
        </div>
    );
};

export default NotFoundPage;