import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setLanguage, setUser} from "../../store/mainSlice";
import './SettingsPage.scss';
import {useTranslation} from "react-i18next";
import {useTelegram} from "../../hooks/useTelegram";
import {buyPredictions, buyPremium} from "../../api/cards";
import {getUserData} from "../../api/user";

const SettingsPage = () => {
    const { t } = useTranslation()
    const {tg} = useTelegram();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.main.user);
    const langList = [
        { code: 'ru', text: 'Русский' },
        { code: 'en', text: 'English' }
    ];
    const [currentLanguage, setCurrentLanguage] = useState('');

    const setNewLanguage = useCallback(() => {
        dispatch(setLanguage(user?.language_code === 'ru' ? 'en' : 'ru'));

        setTimeout(() => updateUserData(), 100);
    }, [user.language_code, dispatch]);

    const getCurrentLanguage = () => {
        let text = langList.find(l => l.code === currentLanguage)?.text;

        if (!text) {
            text = langList.find(l => l.code === 'en')?.text;
        }

        return text;
    }

    const buyMorePredictions = () => {
        tg.showPopup({
            title: t('buyMorePredictions'),
            message: t('howManyPredictionsBuy'),
            buttons: [
                {id: 'cancel', type: 'cancel'},
                {id: 5, type: 'default', text: 5},
                {id: 1, type: 'default', text: 1},
            ]
        }, (buttonId) => {
            if (buttonId !== 'cancel') {
                buyPredictions(parseInt(buttonId))
                    .then(response => {
                        tg.openInvoice(response.result, onSuccessBuyMorePredictions);
                    })
                    .catch(e => console.log(e));
            }
        })
    }

    const onSuccessBuyMorePredictions = (result) => {
        gtag("event", "successfull_payment");

        console.log('!!!>>',result)

        updateUserData();
        // login(tgUser?.id)
        //     .then((response) => {
        //         dispatch(setUser(response.user));
        //
        //         if (response.user?.have_free_prediction) {
        //             getInvoiceLinkHandler(true);
        //         }
        //     })
        //     .catch((e) => console.log(e));
    }

    const buyUnlimitedPredictions = () => {
        buyPremium()
            .then(response => {
                tg.openInvoice(response.result, onSuccessBuyUnlimitedPredictions);
            })
            .catch(e => console.log(e));
    }

    const onSuccessBuyUnlimitedPredictions = (result) => {
        gtag("event", "successfull_payment");

        console.log('!!!>>',result)

        updateUserData();

        // login(tgUser?.id)
        //     .then((response) => {
        //         dispatch(setUser(response.user));
        //
        //         if (response.user?.have_free_prediction) {
        //             getInvoiceLinkHandler(true);
        //         }
        //     })
        //     .catch((e) => console.log(e));
    }

    const updateUserData = () => {
        getUserData()
            .then(response => {
                dispatch(setUser(response));
            })
            .catch((e) => console.log(e));
    }

    useEffect(() => {
        if (user?.language_code) {
            setCurrentLanguage(user.language_code)
        }
    }, [user]);

    return (
        <div className="settingsPage page">
            <div className="settingsPage__element">
                { t('textLanguage') }

                <span
                    className="settingsPage__lang"
                    onClick={ () => setNewLanguage() }
                >{ getCurrentLanguage() }</span>
            </div>

            {(typeof user?.count_predictions === 'number' || user?.count_predictions === '♾️') &&
                <div className="settingsMorePredictions settingsPage__element">
                    {t('predictionsAvailable', [user.count_predictions])}

                    <button
                        className="button"
                        onClick={buyMorePredictions}
                    >{t('buyMore')}</button>
                </div>
            }

            {!user?.is_hq &&
                <div className="settingsUnlimited settingsPage__element">
                    <button
                        className="button"
                        onClick={buyUnlimitedPredictions}
                    >{t('buyUnlimitedPredictions')}</button>
                </div>
            }
        </div>
    );
};

export default SettingsPage;