import React, {useEffect, useState} from 'react';
import './Field.scss';

const Field = React.forwardRef(({type, placeholder, className, errorText, ...props}, ref) => {
    const [value, setValue] = useState('');
    const [isFocused, setFocused] = useState(false);

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, []);

    const inFieldInput = (event) => {
        setValue(event.target.value);

        if (props.onInput) {
            props.onInput(event);
        }
    }

    const onFieldFocus = (event) => {
        setFocused(true);

        if (props.onFocus) {
            props.onFocus(event);
        }
    }

    const onFieldBlur = (event) => {
        setFocused(false);

        if (props.onBlur) {
            props.onBlur(event);
        }
    }

    return (
        <label className={`
            field
            ${className ? className + ' ' : ''}
            ${isFocused ? ' focus' : ''}
            ${value.length ? ' notEmpty' : ''}
        `}>
            <span className="field__inner">
                {type === 'textarea' ?
                    <textarea
                        placeholder={placeholder}
                        {...props}
                        ref={ref}
                        onInput={inFieldInput}
                        onFocus={onFieldFocus}
                        onBlur={onFieldBlur}
                    />
                    :
                    <input
                        type={type}
                        placeholder={placeholder}
                        ref={ref}
                        {...props}
                        onFocus={onFieldFocus}
                        onBlur={onFieldBlur}
                    />
                }

                <span className="field__border" />
            </span>

            {errorText &&
                <span className="field__error">{errorText}</span>
            }
        </label>
    );
});

export default Field;