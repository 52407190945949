import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import i18n from "i18next";
import {setLang} from "../api/user";

export const setLanguage = createAsyncThunk(
    'main/setLanguage',
    async (lang, { dispatch }) => {
        try {
            const response = await setLang(lang);

            if (response) {
                dispatch(setUser(response));
            }
        } catch (e) { console.log(e); }
    }
)

const initialState = {
    user: {},
    appWidth: 0,
    appHeight: 0,
    isFooterVisible: true
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            if (payload.language_code) {
                i18n.changeLanguage(payload.language_code);
            }

            if (payload.is_hq) {
                payload.count_predictions = '♾️';
            }

            state.user = payload;
        },

        updateHasFreePrediction: (state, { payload }) => {
            state.user = {
                ...state.user,
                have_free_prediction: payload
            }
        },

        updatePredictionCount: (state, { payload }) => {
            state.user = {
                ...state.user,
                count_predictions: payload
            }
        },

        setAppSize: (state, { payload: {height, width} }) => {
            if (width) {
                state.appWidth = width;
            }
            if (height) {
                state.appHeight = height;
            }
        },

        showFooter: (state) => {
            state.isFooterVisible = true;
        },

        hideFooter: (state) => {
            state.isFooterVisible = false;
        },
    }
});

export const {
    setUser,
    updateHasFreePrediction,
    updatePredictionCount,
    setAppSize,
    showFooter,
    hideFooter
} = mainSlice.actions;

export default mainSlice.reducer;