import React, {useEffect, useRef, useState} from 'react';
import { getDailyCard, getHistory, getHistoryCard } from '../../api/cards';
import Card from '../../components/Card/Card';
import './HomePage.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {hideFooter, showFooter, updatePredictionCount, updateHasFreePrediction} from "../../store/mainSlice";
import Field from "../../components/Field/Field";
import {useLocation} from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const HomePage = () => {
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.main.user);
    const textRef = useRef(null);
    const [dailyCard, setDailyCard] = useState({});
    const [queryText, setQueryText] = useState('');
    const [isPageLoaded, setPageIsLoaded] = useState(false);
    const [isFieldFocused, setFieldIsFocused] = useState(false);
    const [isErrorVisible, setErrorVisible] = useState(false);
    const [isCardLoaded, setCardIsLoaded] = useState(false);
    const [isFormVisible, setFormIsVisible] = useState(false);

    const showForm = () => {
        gtag("event", "ask_questinon");

        dispatch(hideFooter());
        setFormIsVisible(true);

        setTimeout(() => {
            textRef.current.focus();
        }, 600);
    }

    const closeForm = () => {
        setFormIsVisible(false);
        dispatch(showFooter());
    }

    const getDailyCardData = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (queryText.length) {
            gtag("event", "get_card");

            textRef.current.blur();
            setErrorVisible(false);

            closeForm();

            getDailyCard(queryText)
                .then((response) => {
                    setDailyCard(response);
                    dispatch(updateHasFreePrediction(response.user.have_free_prediction));
                    dispatch(updatePredictionCount(response.user.is_hq ? '♾️' : response.user.count_predictions));
                })
                .catch((e) => console.log(e))
                .finally(() => {
                    setCardIsLoaded(true);
                });
        } else {
            textRef.current.focus();
            setErrorVisible(true);
        }
    };

    const getPrevCardData = () => {
        getHistory({ per_page: 1 })
            .then((response) => {
                const id = response.journals[0]?.id;

                if (typeof id === 'number') {
                    getHistoryCard(id)
                        .then((response) => {
                            setDailyCard(response.journal);
                            setQueryText(response.journal.questions);
                        })
                        .catch((e) => console.log(e))
                        .finally(() => {
                            setCardIsLoaded(true);
                            setPageIsLoaded(true);
                        });
                }
            })
            .catch((e) => {
                console.log(e);
                setCardIsLoaded(true);
            });
    };

    const onQueryFieldChange = e => {
        setErrorVisible(false);
        setQueryText(e.target.value);
    }

    const onQueryFieldFocus = (e) => {
        setFieldIsFocused(true);
    }

    const clearPrediction = () => {
        setDailyCard({});
        setQueryText('');
        setCardIsLoaded(false);
        setPageIsLoaded(true);
    }

    const externalGetNewPrediction = () => {
        clearPrediction();

        showForm();
    }

    useEffect(() => {
        if (locationState?.shouldGetNewCard) {
            externalGetNewPrediction();
        } else if (!user?.have_free_prediction) {
            getPrevCardData();
        } else {
            setPageIsLoaded(true);
        }
    }, []);

    return (
        <div className={
            'homePage page' +
            (isFieldFocused ? ' fieldFocused' : '') +
            (isFormVisible ? ' formOpened' : '')
        }>
            {isPageLoaded ?
                <>
                    <form
                        className={'homePageForm' + (isFormVisible ? ' opened' : '')}
                        onSubmit={getDailyCardData}
                    >
                        <div className="homePageForm__inner">
                            <button
                                className="homePageForm__closeBtn"
                                type="button"
                                onClick={closeForm}
                            >&times;</button>

                            <h2 className="homePage__title">{t('yesOrNo')}</h2>

                            <div className={'homePage__query' + (isErrorVisible ? ' animation' : '')}>
                                <Field
                                    ref={textRef}
                                    type={'textarea'}
                                    value={queryText}
                                    placeholder={t('textWriteQuestion')}
                                    maxLength={100}
                                    errorText={isErrorVisible ? t('errorEmptyQuestion') : ''}
                                    onInput={onQueryFieldChange}
                                    onFocus={onQueryFieldFocus}
                                    onBlur={() => setFieldIsFocused(false)}
                                />
                            </div>

                            <button
                                className="homePageForm__getCardBtn button"
                                type="submit"
                            >{t('getCard')}</button>

                            <p className="homePageForm__description">{t('meaningTooltip')}</p>
                        </div>
                    </form>

                    {(typeof user?.count_predictions === 'number' || user?.count_predictions === '♾️') && !isCardLoaded &&
                        <p className="homePage__availablePredictionsCount">{t('predictionsAvailable', [user.count_predictions])}</p>
                    }

                    {user?.have_free_prediction && !isCardLoaded && (
                        <h2 className="homePage__description">{t('tapOnCard')}</h2>
                    )}

                    <Card
                        card={dailyCard.card}
                        queryText={queryText}
                        inviteLink={dailyCard.invite_link}
                        isFaceDown={!isCardLoaded}
                        onCardClick={!isCardLoaded ? showForm : null}
                        onNewPredictionBtnClick={externalGetNewPrediction}
                    />
                </>
                :
                <Loader/>
            }
        </div>
    );
};

export default HomePage;
